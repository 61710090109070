@import '../../../styles/variables';

.link,
.button {
	background: $lightGray;
	color: $readableGray;
	fill: $readableGray;
	display: inline-flex;
	border-radius: 5px;
	font-size: 1.125rem;
	align-items: center;

	svg {
		height: 1.3rem;
		width: 1.3rem;
		object-fit: contain;
		transition: transform 200ms ease-in;
	}

	&:hover {
		background: $pink;
		color: $white;
		fill: $white;

		svg {
			transform: translateX( -5px );
		}
	}
}

.link {
	text-decoration: none;
	padding: 0.5rem 1rem;
	letter-spacing: 0.0286em; /* dumb letter spacing from MUI button */
	svg {
		margin-right: 0.5rem;
	}
}

.button {
	padding: 0.36rem 1.13rem;
	border: none;
}
